




















import { ref as _ref } from "@vue/composition-api";
import { AuditStatus, updateAudits } from "@/select";
import Preview from "@/components/Preview/index.vue";
import { AuditData } from "@/select2/creativeaudit";
import Creatives, { CreativeData } from "@/select2/creative";
interface Props {
  visible: boolean;
  audit: AuditData;
}
interface Emits {
  (e: "close"): void;
  (e: "updated", data: AuditData): void;
}
const __sfc_main = {};
__sfc_main.props = {
  visible: {
    key: "visible",
    required: false,
    type: Boolean,
    default: false
  },
  audit: null
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  let rejection_reason = _ref("");

  let approved = _ref(null);

  let submitting = false;

  let creative: CreativeData | null = _ref(null);

  onMounted(async () => {
    creative.value = await Creatives.get(props.audit.creative.id);
  });

  function handleClose(done: () => void) {
    emit("close");
    done();
  }

  async function submit() {
    submitting = true;
    const status = approved.value ? AuditStatus.APPROVED : AuditStatus.REJECTED;
    const body = {
      id: props.audit.id,
      creative: props.audit.creative.id,
      inventory: props.audit.inventory.id,
      status,
      rejection_reason: rejection_reason.value
    };

    try {
      const response = await updateAudits([body]);
      const data = response.data[0]; // emit("submitted", { status, rejection_reason })

      emit("updated", data);
      emit("close");
    } finally {
      submitting = false;
    }
  }

  return {
    emit,
    rejection_reason,
    approved,
    creative,
    handleClose,
    submit
  };
};

__sfc_main.components = Object.assign({
  Preview
}, __sfc_main.components);
export default __sfc_main;
