import { ListParams } from "./api/base"
import Generate from "./api/crud"
import { Lookup } from "@/select"

export interface Params extends ListParams {
	name?: string
}

export interface Creative {
	id?: number
	name: string
	type: number | null
	agency?: Lookup
	advertiser?: Lookup
	is_active: boolean
	tag: string
	format?: number
	asset_url?: string
	ak_asset_url?: string
	destination_url?: string
	duration?: number
	width?: number
	height?: number
}

export interface CreativeForm extends Omit<Creative, "id"> {}
export interface CreativeData extends Creative {
	id: number
	agency: Lookup
	advertiser: Lookup
	format: number
}

export const defaultCreativeData: Creative = {
	name: "",
	type: null,
	is_active: true,
	tag: "",
}

export default Generate<CreativeForm, CreativeData>("/creatives")
