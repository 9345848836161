















































































































import { ref as _ref } from "@vue/composition-api";
import { AuditStatus, lookupInventories, updateAudits } from "@/select";
import { getAudits } from "@/select/api/audit";
import Pagination from "@/components/Pagination/index.vue";
import { capitalize, handleErrorResponse } from "@/utils";
import PreviewDialog from "@/views/audits/PreviewDialog.vue";
import { Lookup } from "@/select2/base";
import router from "@/router";
import { Message } from "element-ui";
import { AuditData } from "@/select2/creativeaudit";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const isAdmin = User.isAdmin;
  const isDemand = User.roles.includes("demand user");
  const defaultListQuery = {
    page: 1,
    page_size: 20,
    query: undefined,
    ordering: "-id"
  };

  let listQuery = _ref({ ...defaultListQuery
  });

  let previewDialogVisible = _ref(false);

  let previewedAudit: any = _ref(null);

  let loading = _ref(true);

  let audits: any[] = _ref([]);

  let inventories: Lookup[] = _ref([]);

  let count = _ref(0);

  async function load() {
    loading.value = true;
    const {
      data
    } = await getAudits(listQuery.value);
    audits.value = data.results.map((item: AuditData) => ({ ...item,
      modified: false,
      disabled: item.status !== 1,
      original_status: item.status,
      inventory: isDemand ? item.inventory.name : item.inventory
    }));
    count.value = data.count;
    loading.value = false;
  }

  onMounted(async () => {
    load();
    const params: {
      publisher__requires_auditing?: boolean;
    } = {};
    if (isAdmin) params.publisher__requires_auditing = true;
    if (!isDemand) inventories.value = (await lookupInventories(params)).data;
  });

  async function search() {
    router.push({
      query: {
        q: listQuery.value.query
      }
    });
    listQuery.value.page = 1;
    load();
  }

  const statusFilters = [{
    text: "Pending",
    value: 1
  }, {
    text: "Approved",
    value: 2
  }, {
    text: "Rejected",
    value: 3
  }];
  const liveFilters = [{
    text: "Live",
    value: "true"
  }, {
    text: "Not Live",
    value: "false"
  }];

  async function preview(row) {
    previewedAudit.value = row;
    previewDialogVisible.value = true;
  }

  function handleClose() {
    previewDialogVisible.value = false;
  }

  function handleUpdated(data) {
    const idx = audits.value.findIndex(audit => audit.id === data.id);
    audits.value[idx].status = data.status;
    audits.value[idx].original_status = data.status;
    audits.value[idx].rejection_reason = data.rejection_reason;
    audits.value[idx].disabled = data.status !== 1;
    previewDialogVisible.value = false;
  }

  function statusType(s: AuditStatus) {
    if (s === AuditStatus.APPROVED) return "success";
    if (s === AuditStatus.REJECTED) return "danger";
    return "";
  }

  function statusName(s: AuditStatus) {
    return capitalize(AuditStatus[s]);
  }

  async function handleSave() {
    loading.value = true;
    const changed = audits.value.filter(a => a.modified).map((a: any) => ({
      id: a.id,
      status: a.status,
      inventory: a.inventory.id,
      rejection_reason: a.rejection_reason
    }));

    try {
      await updateAudits(changed, true);
      Message.success(`Audited ${changed.length} creative audits`);
      load();
    } catch (e) {
      Message.error(handleErrorResponse(e));
    } finally {
      loading.value = false;
    }
  }

  function filterHandler(filters) {
    loading.value = true;
    listQuery.value = { ...filters
    };
    listQuery.value.page = 1;
    load();
    loading.value = false;
  }

  return {
    isAdmin,
    isDemand,
    listQuery,
    previewDialogVisible,
    previewedAudit,
    loading,
    audits,
    inventories,
    count,
    load,
    search,
    statusFilters,
    liveFilters,
    preview,
    handleClose,
    handleUpdated,
    statusType,
    statusName,
    handleSave,
    filterHandler
  };
};

__sfc_main.components = Object.assign({
  Pagination,
  PreviewDialog
}, __sfc_main.components);
export default __sfc_main;
